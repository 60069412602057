body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 0px;
  gap: 6px 10px;
  margin-bottom: 0px;
}
.grid-1-col {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-2-col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-3-col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-4-col {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-5-col {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-6-col {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-7-col {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-8-col {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
