.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-main .MuiDataGrid-columnHeaders{
  background-color: #2595be9c;
  color: #000000;
}


.tbl_stripped thead tr th {
  background-color :'#2595be9c'
}



.MuiDataGrid-menuIcon {
  width: unset !important;
  visibility: visible !important;
}

/* .MuiGridToolbarQuickFilter-root{
  color: white;
} */


.body {
  align-items: center;
  align-content: center;
}

.list-group-item.active {
  background-color: black;
  border-color: black;
}

.list-group-item-action:hover {
  color: #ffffff;
  background-color: #c62828;
}

.cut-row .list-group-item {
  padding: 0.75rem 10px;
  font-size: 15px;
}

.tbl_stripped {
  border: 2px solid #d5d5d5;
  border-collapse: collapse !important;
  word-wrap: break-word;
  table-layout: fixed;
}
.tbl_stripped thead.blue_head tr th {
  background-color: #337ab7;
  color: #fff;
}
.tbl_stripped thead.light_blue_head tr th {
  background-color: #ffffff;
}
.tbl_stripped th,
.tbl_stripped td {
  border: 1px solid #d5d5d5;
  font-size: 13px !important;
  padding: 8px 5px !important;
}
.tbl_stripped thead tr th {
  background-color: #ffffff;
  font-weight: 600;
  vertical-align: middle;
  word-wrap: break-word;
}

.tbl_stripped tbody tr:nth-child(even) th,
.tbl_stripped tbody tr:nth-child(even) td {
  background-color: #ffffff;
}
.tbl_stripped tbody tr:hover th,
.tbl_stripped tbody tr:hover td {
  background-color: #fcf8e3;
}

.tbl_stripped tfoot .MuiToolbar-root {
  min-height: auto;
}

.tbl_stripped.vAlignTop tbody th,
.tbl_stripped.vAlignTop tbody td {
  vertical-align: top;
}

.tbl_stripped tfoot.sticky {
  position: sticky;
  inset-block-end: 0;
  background-color: #fff;
}

.tbl_stripped.noBdr tr td {
  border: 0;
}