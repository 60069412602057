.blWelcome {
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url('./wallpaper.jpeg') no-repeat center center;
  background-size: cover;
}

.ezmeterlogo {
  height: 130px;
  width: 140px;
}

.pageHead {
  margin-bottom: 10px;
  font-size: 20px;
}
